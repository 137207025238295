var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "updateIOS" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "text" },
          on: {
            click: function ($event) {
              return _vm.$router.go(-1)
            },
          },
        },
        [_vm._v("<<返回")]
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("TitleModule", { attrs: { title: _vm.title } }),
          _c(
            "div",
            { staticClass: "searchWrapper" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticStyle: { width: "600px" },
                  attrs: {
                    "label-position": "right",
                    disabled: !_vm.isEdit,
                    "label-width": "120px",
                    model: _vm.formInline,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "版本号", prop: "versionName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入版本号",
                          disabled:
                            _vm.formInline.upgradeStatus === 1 || !_vm.isEdit,
                        },
                        model: {
                          value: _vm.formInline.versionName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "versionName", $$v)
                          },
                          expression: "formInline.versionName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "下载链接", prop: "appStoreUrl" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入下载链接",
                          disabled:
                            _vm.formInline.upgradeStatus === 1 || !_vm.isEdit,
                          maxlength: "500",
                        },
                        model: {
                          value: _vm.formInline.appStoreUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "appStoreUrl", $$v)
                          },
                          expression: "formInline.appStoreUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h2", [_vm._v("基础配置")]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "升级方式", prop: "updateType" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.formInline.updateType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "updateType", $$v)
                            },
                            expression: "formInline.updateType",
                          },
                        },
                        [_vm._v("推荐升级")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.formInline.updateType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "updateType", $$v)
                            },
                            expression: "formInline.updateType",
                          },
                        },
                        [_vm._v("强制升级")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "更新说明", prop: "updateDesc" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入更新说明",
                          maxlength: "200",
                          autosize: { minRows: 4, maxRows: 8 },
                        },
                        model: {
                          value: _vm.formInline.updateDesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "updateDesc", $$v)
                          },
                          expression: "formInline.updateDesc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h2", [_vm._v("高级配置")]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "升级时间", prop: "upgradeType" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: 1,
                            disabled:
                              _vm.formInline.upgradeStatus === 1 || !_vm.isEdit,
                          },
                          model: {
                            value: _vm.formInline.upgradeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "upgradeType", $$v)
                            },
                            expression: "formInline.upgradeType",
                          },
                        },
                        [_vm._v(" 立即升级 ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          staticStyle: { "margin-right": "20px" },
                          attrs: {
                            label: 2,
                            disabled:
                              _vm.formInline.upgradeStatus === 1 || !_vm.isEdit,
                          },
                          model: {
                            value: _vm.formInline.upgradeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "upgradeType", $$v)
                            },
                            expression: "formInline.upgradeType",
                          },
                        },
                        [_vm._v("定时升级 ")]
                      ),
                      _c("el-date-picker", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.formInline.upgradeType !== 1,
                            expression: "formInline.upgradeType !== 1",
                          },
                        ],
                        attrs: {
                          clearable: false,
                          editable: false,
                          disabled:
                            _vm.formInline.upgradeStatus === 1 || !_vm.isEdit,
                          "picker-options": _vm.pickerOptions,
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择日期时间",
                        },
                        on: { change: _vm.upgradeTimeSelect },
                        model: {
                          value: _vm.formInline.upgradeTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "upgradeTime", $$v)
                          },
                          expression: "formInline.upgradeTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "40px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isEdit,
                          expression: "isEdit",
                        },
                      ],
                      staticStyle: { width: "88px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.submitData },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.preservation")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "88px" },
                      attrs: { type: "info" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.isEdit ? "取消" : "返回"))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
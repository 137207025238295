<template>
  <div class="updateIOS">
    <el-button type="text" @click="$router.go(-1)">&lt;&lt;返回</el-button>
    <!--主体内容-->
    <div class="content">
      <TitleModule :title="title"></TitleModule>
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form
          label-position="right"
          :disabled="!isEdit"
          label-width="120px"
          :model="formInline"
          style="width: 600px"
          :rules="rules"
          ref="form"
        >
          <el-form-item label="版本号" prop="versionName">
            <el-input
              type="text"
              v-model="formInline.versionName"
              placeholder="请输入版本号"
              :disabled="formInline.upgradeStatus === 1 || !isEdit"
            ></el-input>
          </el-form-item>
          <el-form-item label="下载链接" prop="appStoreUrl">
            <el-input
              type="text"
              v-model="formInline.appStoreUrl"
              placeholder="请输入下载链接"
              :disabled="formInline.upgradeStatus === 1 || !isEdit"
              maxlength="500"
            ></el-input>
          </el-form-item>
          <h2>基础配置</h2>
          <el-form-item label="升级方式" prop="updateType">
            <el-radio v-model="formInline.updateType" :label="2">推荐升级</el-radio>
            <el-radio v-model="formInline.updateType" :label="1">强制升级</el-radio>
          </el-form-item>
          <el-form-item label="更新说明" prop="updateDesc">
            <el-input
              type="textarea"
              placeholder="请输入更新说明"
              maxlength="200"
              :autosize="{ minRows: 4, maxRows: 8 }"
              v-model="formInline.updateDesc"
            ></el-input>
          </el-form-item>
          <h2>高级配置</h2>
          <el-form-item label="升级时间" prop="upgradeType">
            <el-radio
              v-model="formInline.upgradeType"
              :label="1"
              :disabled="formInline.upgradeStatus === 1 || !isEdit"
            >
              立即升级
            </el-radio>
            <el-radio
              v-model="formInline.upgradeType"
              :label="2"
              style="margin-right: 20px"
              :disabled="formInline.upgradeStatus === 1 || !isEdit"
              >定时升级
            </el-radio>
            <el-date-picker
              v-show="formInline.upgradeType !== 1"
              :clearable="false"
              :editable="false"
              :disabled="formInline.upgradeStatus === 1 || !isEdit"
              :picker-options="pickerOptions"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="formInline.upgradeTime"
              type="datetime"
              @change="upgradeTimeSelect"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div style="text-align: center; margin-top: 40px">
          <el-button type="primary" @click="submitData" style="width: 88px" v-show="isEdit"
            >{{ $t('button.preservation') }}</el-button
          >
          <el-button type="info" @click="$router.go(-1)" style="width: 88px">{{
            isEdit ? "取消" : "返回"
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TitleModule from "@/components/titleModule/titleModule";
import formatDate from "format-datetime";

export default {
  data() {
    return {
      title: "添加iOS新版本",
      isEdit: true,
      formInline: {
        upgradeStatus: 0,
        updateType: 2,
        applicationName: "",
        productId: "",
        appStoreUrl: "",
        versionName: "",
        updateDesc: "",
        upgradeType: 1,
        upgradeTime: formatDate(new Date(), "yyyy-MM-dd HH:mm:ss"),
      },
      rules: {
        applicationName: [{ required: true, message: "请输入应用名", trigger: "blur" }],
        versionName: [
          { required: true, message: "请输入版本号", trigger: "blur" },
          {
            pattern: /^((\d{2}(\.\d{2}){2})|(\d(\.\d){2}))$/,
            message: "请输入正确的版本号，例如：xx.xx.xx或 x.x.x",
            trigger: "blur",
          },
        ],
        updateType: [{ required: true, message: "请选择升级方式", trigger: "change" }],
        upgradeType: [{ required: true, message: "请选择升级时间", trigger: "change" }],
        updateDesc: [{ required: true, message: "请输入更新说明", trigger: "blur" }],
        appStoreUrl: [
          {
            required: true,
            message: "请输入下载链接",
            trigger: "blur",
          },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  methods: {
    upgradeTimeSelect(value) {
      let now = new Date().getTime();
      let setTime = new Date(value).getTime();
      if (setTime < now) {
        this.$message.closeAll();
        this.$message.error("选择时间必须大于当前时间");
        return false;
      } else {
        return true;
      }
    },
    queryDetail(applicationId, recordId) {
      this.$axios
        .get("/acb/2.0/application/record/queryDetail", {
          data: {
            applicationId,
            recordId,
          },
        })
        .then((res) => {
          if (res.state === 0) {
            let newDetail = res.value;
            for (let key in newDetail) {
              // eslint-disable-next-line no-prototype-builtins
              if (newDetail.hasOwnProperty(key) && newDetail[key]) {
                this.formInline[key] = newDetail[key];
              }
            }
          }
        });
    },
    // 更新新版时候，查询上一次的详情
    getInfo() {
      this.$axios
        .get("/acb/2.0/application/record/queryLastInfo", {
          data: {
            applicationId: this.$route.query.applicationId,
          },
        })
        .then((res) => {
          if (res.state === 0) {
            this.formInline.appStoreUrl = res.value.appStoreUrl;
          }
        });
    },
    submitData() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (
            this.formInline.upgradeType === 2 &&
            this.formInline.upgradeStatus !== 1 &&
            !this.upgradeTimeSelect(this.formInline.upgradeTime)
          ) {
            return false;
          }
          this.$confirm("您确定要保存此操作吗？").then((res) => {
            if (res === "confirm") {
              let url =
                this.isEdit === "add"
                  ? "/acb/2.0/application/record/add"
                  : "/acb/2.0/application/record/update";
              let methods = this.isEdit === "add" ? "post" : "post";
              this.$axios[methods](
                url,
                {
                  data: {
                    applicationId: this.applicationId,
                    updateType: this.formInline.updateType,
                    updateDesc: this.formInline.updateDesc,
                    upgradeType: this.formInline.upgradeType,
                    upgradeTime: this.formInline.upgradeTime,
                    recordId: this.$route.query.recordId,
                    channelList: [
                      {
                        channelName: "appStore",
                        downloadUrl: this.formInline.appStoreUrl,
                        versionName: this.formInline.versionName,
                      },
                    ],
                  },
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              ).then((res) => {
                if (res.state === 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                  });
                  // this.$router.push("/appmanager");
                  this.$router.go(-1);
                } else {
                  this.$alert(res.desc);
                }
              });
            }
          });
        }
      });
    },
  },
  components: {
    TitleModule,
  },
  created() {
    if (this.$route.query.applicationId) {
      this.applicationId = this.$route.query.applicationId;
      this.isEdit = this.$route.query.isEdit;
      if (this.isEdit === "add") {
        this.title = "添加iOS新版本";
        this.getInfo();
      } else {
        if (this.isEdit) {
          this.title = "更新iOS新版本";
        } else {
          this.title = "查看详情";
        }
        this.queryDetail(this.$route.query.applicationId, this.$route.query.recordId);
      }
    }
  },
  mounted() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  background: #ffffff;
  overflow: hidden;
  border: 1px solid #c0ccda;
  border-radius: 4px;
  padding: 20px;
  width: 960px;
}

.searchWrapper h2 {
  line-height: 50px;
  font-size: 14px;
}
</style>
